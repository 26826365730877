import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { add } from 'date-fns';
import { Maybe } from 'graphql/jsutils/Maybe';

type CacheEntry<T> = { entry: T; expiration: number };

/**
 * A hook that persists state to local storage.
 * @param key - The key to use for local storage.
 * @param expires - The {@link Duration} after which the state should be considered expired.
 * @param initialState - The initial state.
 */
export function usePersistedState<T>(
  key: string,
  expires: Duration,
  initialState: T,
): [T, Dispatch<SetStateAction<T>>, boolean] {
  const [currentState, setCurrentState] = useState<T>(() => {
    try {
      const cachedItem: Maybe<T> = cacheGet<T>(key);
      if (cachedItem) {
        return cachedItem;
      }
      // Default to returning the provided `initialState`
      return initialState;
    } catch (error) {
      return initialState;
    }
  });

  const [isExpired, setIsExpired] = useState<boolean>(() => {
    try {
      const cachedItem: string | null = localStorage.getItem(key);
      if (cachedItem) {
        const cacheEntry: CacheEntry<T> = JSON.parse(cachedItem);
        return cacheEntry.expiration < new Date().getTime();
      }
      return true;
    } catch (error) {
      return true;
    }
  });

  // Define a function to update the state and cache. This will be returned instead of
  // `setCurrentState` so that the caller doesn't have to worry about the cache. When a call
  // to this function occurs, we know we have a value provided by the caller.
  const setAndPersistState: Dispatch<SetStateAction<T>> = (value: T | ((val: T) => T)) => {
    try {
      // Get the value to store. If the caller provided a function, call it to get the value.
      const valueToStore = value instanceof Function ? value(currentState) : value;
      setCurrentState(valueToStore);
      setIsExpired(false);
      cacheSet(key, valueToStore, expires);
    } catch (error) {
      localStorage.removeItem(key);
    }
  };

  // Check expiration status whenever the component mounts or the key changes
  useEffect(() => {
    try {
      const cachedItem: string | null = localStorage.getItem(key);
      if (cachedItem) {
        const cacheEntry: CacheEntry<T> = JSON.parse(cachedItem);
        setIsExpired(cacheEntry.expiration < new Date().getTime());
      } else {
        setIsExpired(true);
      }
    } catch (error) {
      setIsExpired(true);
    }
  }, [key]);

  return [currentState, setAndPersistState, isExpired];
}

/**
 * Deletes an item from the localStorage cache.
 * @param key The key to delete
 */
export function cacheDelete(key: string): void {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    // Ignore
  }
}

/**
 * Gets an item from the localStorage cache.
 * @param key The key of the value to get
 */
export function cacheGet<T>(key: string): Maybe<T> {
  try {
    const cachedItem: string | null = localStorage.getItem(key);
    if (cachedItem) {
      // If we have a cached item, make sure it isn't expired
      const cacheEntry: CacheEntry<T> = JSON.parse(cachedItem);
      if (cacheEntry.expiration < new Date().getTime()) {
        // Expired; remove from cache
        localStorage.removeItem(key);
      } else {
        // Not expired; return
        return cacheEntry.entry;
      }
    }
    // Default to returning the provided `initialState`
    return undefined;
  } catch (error) {
    localStorage.removeItem(key);
    return undefined;
  }
}

/**
 * Sets an item in the localStorage cache.
 * @param key The key to use for the cache entry
 * @param valueToStore The value to store
 * @param expires The {@link Duration} after which the state should be considered expired.
 */
export function cacheSet<T>(key: string, valueToStore: T, expires: Duration): void {
  try {
    // If an expiration is already defined, and is still valid, we'll use that. Without
    // this check, we'd be extending the expiration every time the state is updated.
    let nextExpire = add(new Date(), expires).getTime();
    const cachedItem: string | null = localStorage.getItem(key);
    if (cachedItem) {
      const cacheEntry: CacheEntry<T> = JSON.parse(cachedItem);
      if (cacheEntry.expiration > new Date().getTime()) {
        nextExpire = cacheEntry.expiration;
      }
    }

    // Store the value with an expiration date.
    localStorage.setItem(
      key,
      JSON.stringify({
        entry: valueToStore,
        expiration: nextExpire,
      } as CacheEntry<T>),
    );
  } catch (error) {
    localStorage.removeItem(key);
  }
}
