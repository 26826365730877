import { CrmLead } from './lead';
import { CrmUser } from './user';
import { CrmCallRecordingTypeEnum } from './callRecording';

export type CrmCallRecordingsAndPayloadsPaged = {
  crm: {
    userOptions: {
      callRecordingsAndPayloadsPage: {
        totalCount: number;
        callRecordingsAndPayloads: CrmCallRecordingsAndPayloads[];
      };
    };
  };
};

export type CrmCallRecordingsAndPayloads = CrmCallActivityCallRecording | CrmCallActivityCallPayload;

export type CrmCallActivityCallPayloadReceiverType = CrmUser | CrmLead;
export type CrmCallActivityCallPayloadCallerType = CrmUser | CrmLead;

export type CrmCallActivityCallPayload = {
  id: number;
  sid: string;
  direction: string;
  caller: string;
  receiver: string;
  status: string;
  answeredBy: string;
  payload: string;
  duration: string;
  payloadCreatedAt: string;
  recordingUrl: string;
  receiverData: CrmCallActivityCallPayloadReceiverType;
  callerData: CrmCallActivityCallPayloadCallerType;
};

export type CrmCallActivityCallRecordingReceiverType = CrmUser | CrmLead;
export type CrmCallActivityCallRecordingCallerType = CrmUser | CrmLead;

export type CrmCallActivityCallRecording = {
  id: number;
  callSid: string;
  dialer: string;
  receiver: string;
  duration: string;
  summary: string;
  startTime: string;
  mediaUrl: string;
  recordingCreatedAt: string;
  endTime: string;
  type: CrmCallRecordingTypeEnum;
  receiverData: CrmCallActivityCallRecordingReceiverType;
  callerData: CrmCallActivityCallRecordingCallerType;
};

export type CrmCallActivityCallLogs = {
  id: number;
  callerName: string;
  createdAt: string;
  mediaUrl: string;
  status: string;
  direction: string;
  duration: string;
  caller: string;
  hasCallerData: boolean;
  leadDetails: CrmLead | null;
};

const mapCallPayloadCallerData = (callerData: CrmCallActivityCallPayloadCallerType) => {
  if (callerData?.__typename === 'CrmUser') {
    const user = callerData as CrmUser;
    return {
      userId: user.id,
      userEmail: user.email,
      userFirstName: user.firstName,
      userLastName: user.lastName,
      userContactNo: user.twilio.projectedAddress,
    };
  }

  if (callerData?.__typename === 'CrmLead') {
    const lead = callerData as CrmLead;
    return {
      leadId: lead.id,
      leadName: lead.name,
      leadContactNo: lead.contactNo,
    };
  }

  return;
};

const mapCallRecordingCallerData = (callerData: CrmCallActivityCallRecordingCallerType) => {
  if (callerData?.__typename === 'CrmUser') {
    const user = callerData as CrmUser;
    return {
      userId: user.id,
      userEmail: user.email,
      userFirstName: user.firstName,
      userLastName: user.lastName,
      userContactNo: user.twilio.projectedAddress,
    };
  }

  if (callerData?.__typename === 'CrmLead') {
    const lead = callerData as CrmLead;
    return {
      leadId: lead.id,
      leadName: lead.name,
      leadContactNo: lead.contactNo,
    };
  }

  return;
};

export const callActivityMapCallPayloadToCallLogs = (call: CrmCallActivityCallPayload): CrmCallActivityCallLogs => {
  let callerName = '';

  if (call.callerData) {
    if (call.callerData.__typename === 'CrmUser') {
      callerName = `${mapCallPayloadCallerData(call?.callerData)?.userFirstName ?? ''} ${
        mapCallPayloadCallerData(call?.callerData)?.userLastName ?? ''
      }`;
    } else {
      callerName = `${mapCallPayloadCallerData(call?.callerData)?.leadName ?? ''}`;
    }
  }

  return {
    id: call.id,
    callerName: callerName,
    createdAt: call.payloadCreatedAt,
    mediaUrl: call.recordingUrl,
    status: call.status,
    direction: call.direction,
    duration: call.duration,
    caller: call.caller,
    hasCallerData: !!call.callerData ?? false,
    leadDetails: call?.callerData?.__typename === 'CrmLead' ? (call.callerData as CrmLead) : null,
  };
};

export const callActivityMapRecordingToCallLogs = (call: CrmCallActivityCallRecording): CrmCallActivityCallLogs => {
  let callerName = '';

  if (call.callerData) {
    if (call.callerData.__typename === 'CrmUser') {
      callerName = `${mapCallRecordingCallerData(call?.callerData)?.userFirstName ?? ''} ${
        mapCallRecordingCallerData(call?.callerData)?.userLastName ?? ''
      }`;
    } else {
      callerName = `${mapCallRecordingCallerData(call?.callerData)?.leadName ?? ''}`;
    }
  }

  return {
    id: call.id,
    callerName: callerName,
    createdAt: call.recordingCreatedAt,
    mediaUrl: call.mediaUrl,
    status: 'voicemail',
    direction: '',
    duration: call.duration,
    caller: call.dialer,
    hasCallerData: !!call.callerData ?? false,
    leadDetails: call?.callerData?.__typename === 'CrmLead' ? (call.callerData as CrmLead) : null,
  };
};
