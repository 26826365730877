import { useContext } from 'react';
import { CurrentUserContext } from '../../../contexts/CurrentUserContext';

/**
 * Checks if the current user has any of the specified permissions.
 *
 * @param {string[]} permissions - The list of permissions to check against the current user's permissions.
 * @returns {boolean} - Returns true if the current user has at least one of the specified permissions, otherwise false.
 */
export const HasOnePermission = (permissions: string[]): boolean => {
  const currentUser = useContext(CurrentUserContext);
  if (!currentUser) {
    return false;
  }
  const userPermissions = currentUser.permissions.map(permission => permission.name);

  return permissions.some(permission => userPermissions.includes(permission));
};
