import { configureStore } from '@reduxjs/toolkit';
import { leadsSlice } from './leadsSlice';
import { projectsSlice } from './projectsSlice';
import { taskSchedulesSlice } from './taskSchedulesSlice';
import { callPayloadsSlice } from './twilio/callPayloadSlice';
import { availableTwilioNumbersSlice } from './twilio/availableTwilioNumbersSlice';
import { voicemailsSlice } from './twilio/voiceMailSlice';
import { purchasedNumbersSlice } from './admin/phoneNumbers/purchasedNumbersSlice';
import { financeOffersSlice } from './financeOffersSlice';
import { proposalLoansSlice } from './proposalLoansSlice';

export const store = configureStore({
  reducer: {
    leads: leadsSlice.reducer,
    projects: projectsSlice.reducer,
    taskSchedules: taskSchedulesSlice.reducer,
    callPayloads: callPayloadsSlice.reducer,
    availableTwilioNumbers: availableTwilioNumbersSlice.reducer,
    voicemails: voicemailsSlice.reducer,
    purchasedNumbers: purchasedNumbersSlice.reducer,
    financeOffers: financeOffersSlice.reducer,
    proposalLoans: proposalLoansSlice.reducer,
  },
});
