import { OAuthProvider, OAuthScope } from '@aws-amplify/core/dist/esm/singleton/Auth/types';
import axios from 'axios';

export type Env = {
  clientId: string;
  userPoolId: string;
  domain: string;
  redirects: string[];
  scopes: OAuthScope[];
  hub_url: string;
  providers: OAuthProvider[];
};

//Function to fetch Amplify config from the backend
export async function fetchEnv() {
  try {
    const response = await axios.get('/vars');
    return response.data;
  } catch (error) {
    //eslint-disable-next-line no-console
    console.error('Failed to fetch Amplify configuration:', error);
    throw new Error('Failed to initialize Amplify');
  }
}
