import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

export enum BreakpointType {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export const useBreakpoints = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only(BreakpointType.SM));
  const isXsScreen = useMediaQuery(theme.breakpoints.only(BreakpointType.XS));
  const isMdScreen = useMediaQuery(theme.breakpoints.only(BreakpointType.MD));
  const isLgScreen = useMediaQuery(theme.breakpoints.only(BreakpointType.LG));
  const isXlScreen = useMediaQuery(theme.breakpoints.only(BreakpointType.XL));

  return { isSmallScreen, isXsScreen, isMdScreen, isLgScreen, isXlScreen };
};
