import { CrmUser } from '../../crm/user';
import { CrmLead } from '../../crm/lead';
import { ProjectType } from '../../project';

export type IdentityNotes = {
  identity: {
    user: {
      note: IdentityNote[];
    };
  };
};

export type IdentityNote = {
  id: string;
  contextId: string;
  context: IdentityContextType;
  contextPath: string;
  content: string;
  createdById: string;
  createdBy: CrmUser;
  createdAt: string;
  mentions: Mention[];
};

export enum IdentityContextType {
  TASK_SCHEDULE = 'TASK_SCHEDULE',
  PROJECT_REQUIREMENT = 'PROJECT_REQUIREMENT',
}

export type Mention = {
  mentionId: string;
  mentionType: MentionType;
  mentionContext: CrmUser | CrmLead | ProjectType;
};

export type MentionInput = {
  mentionId: string;
  mentionType: MentionType;
};

export enum MentionType {
  USER = 'USER',
  LEAD = 'LEAD',
  PROJECT = 'PROJECT',
}
