import { CrmUser } from './user';
import { CrmLead } from './lead';

export type CrmCallRecordings = { crm: { userOptions: { callRecordings: CrmCallRecording[] } } };

export enum CrmCallRecordingTypeEnum {
  VOICEMAIL = 'VOICEMAIL',
  CALL = 'CALL',
}

export type CrmCallRecordingsPaged = {
  crm: {
    userOptions: {
      callRecordingsPaged: {
        totalCount: number;
        callRecordings: CrmCallRecording[];
      };
    };
  };
};

export type CrmCallRecordingReceiverType = CrmUser | CrmLead;
export type CrmCallRecordingCallerType = CrmUser | CrmLead;

export type CrmCallRecording = {
  id: number;
  callSid: string;
  dialer: string;
  receiver: string;
  duration: string;
  summary: string;
  startTime: string;
  mediaUrl: string;
  createdAt: string;
  endTime: string;
  type: CrmCallRecordingTypeEnum;
  receiverData: CrmCallRecordingReceiverType;
  callerData: CrmCallRecordingCallerType;
};

export const mapCallRecordingCallerData = (callerData: CrmCallRecordingCallerType) => {
  if (callerData?.__typename === 'CrmUser') {
    const user = callerData as CrmUser;
    return {
      userId: user.id,
      userEmail: user.email,
      userFirstName: user.firstName,
      userLastName: user.lastName,
      userContactNo: user.twilio.projectedAddress,
    };
  }

  if (callerData?.__typename === 'CrmLead') {
    const lead = callerData as CrmLead;
    return {
      leadId: lead.id,
      leadName: lead.name,
      leadContactNo: lead.contactNo,
    };
  }

  return;
};
