export interface AddressResult {
  streetName: string;
  city: string;
  state: string;
  zipCode: string;
  streetNumber: string;
  country: string;
  latitude: number;
  longitude: number;
}
export const getMapAddress = (result: google.maps.GeocoderResult): AddressResult => {
  const streetNumber = result.address_components.find(item => item.types.includes('street_number'))?.long_name ?? '';
  const streetName = result.address_components.find(item => item.types.includes('route'))?.long_name ?? '';
  const city = result.address_components.find(item => item.types.includes('locality'))?.long_name ?? '';
  const state =
    result.address_components.find(item => item.types.includes('administrative_area_level_1'))?.long_name ?? '';
  const zipCode = result.address_components.find(item => item.types.includes('postal_code'))?.long_name ?? '';
  const country = result.address_components.find(item => item.types.includes('country'))?.long_name ?? '';
  const latitude = result.geometry.location.lat();
  const longitude = result.geometry.location.lng();

  return {
    streetName,
    streetNumber,
    city,
    state,
    zipCode,
    country,
    latitude,
    longitude,
  };
};
