import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectType } from '../custom/types/project';

interface ProjectsState {
  data: ProjectType[];
  isDataLoaded: boolean;
  totalProjects: number;
}

const initialState: ProjectsState = {
  data: [],
  isDataLoaded: false,
  totalProjects: 0,
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjectsData(state, action: PayloadAction<ProjectType[]>) {
      state.data = action.payload;
    },
    setIsDataLoaded(state, action: PayloadAction<boolean>) {
      state.isDataLoaded = action.payload;
    },
    setTotalProjects(state, action: PayloadAction<number>) {
      state.totalProjects = action.payload;
    },
  },
});

export const { setProjectsData, setIsDataLoaded, setTotalProjects } = projectsSlice.actions;
