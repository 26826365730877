import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CrmCallPayload } from '../../custom/types/crm/callPayload';

interface CallPayloadsState {
  data: CrmCallPayload[];
  isDataLoaded: boolean;
  totalCallPayloads: number;
}

const initialState: CallPayloadsState = {
  data: [],
  isDataLoaded: false,
  totalCallPayloads: 0,
};

export const callPayloadsSlice = createSlice({
  name: 'callPayloads',
  initialState,
  reducers: {
    setCallPayloadsData(state, action: PayloadAction<CrmCallPayload[]>) {
      state.data = action.payload;
    },
    setIsDataLoaded(state, action: PayloadAction<boolean>) {
      state.isDataLoaded = action.payload;
    },
    setTotalCallPayloads(state, action: PayloadAction<number>) {
      state.totalCallPayloads = action.payload;
    },
  },
});

export const { setCallPayloadsData, setIsDataLoaded, setTotalCallPayloads } = callPayloadsSlice.actions;
