import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { FieldProps, FormikErrors, FormikTouched } from 'formik';
import { CrmUserMentions } from '../user';
import { CrmLeadsPaged } from '../lead';
import React from 'react';
import { AllProjectsType } from '../../project';
import { SuggestionDataItem } from 'react-mentions';

export type MentionQueryHandlers = {
  queries: MentionQueryTypes;
  setUpdatedSuggestionDataObjectList?: React.Dispatch<React.SetStateAction<MentionDataTypes[]>>;
};

export type MentionQueryTypes = {
  /** Handler for users query */
  users: UsersQueryHandlers;
  /** Handler for leads query */
  leads: LeadsQueryHandlers;
  /** Handler for projects query */
  projects: ProjectsQueryHandlers;
};

type UsersQueryHandlers = {
  /** Query for getting the users */
  getUsers: LazyQueryExecFunction<CrmUserMentions, OperationVariables>;
  /** Default input for getUsers query */
  defaultInputFilters: UsersDefaultFilterInputs;
};

type LeadsQueryHandlers = {
  /** Query for getting the leads */
  getLeads: LazyQueryExecFunction<CrmLeadsPaged, OperationVariables>;
  /** Default input for getLeads query */
  defaultInputFilters: LeadsDefaultFilterInputs;
};

type ProjectsQueryHandlers = {
  /** Query for getting the projects */
  getProjects: LazyQueryExecFunction<AllProjectsType, OperationVariables>;
};

type UsersDefaultFilterInputs = {
  /** Input to be use to filter out the curren user from the list of users */
  userId: string;
};

type LeadsDefaultFilterInputs = {
  /** Input to be use distinguish leads that is assigned to current user */
  assignedBy: string;
};

/**
 * Trigger Characters
 */
export enum AvailableTriggerCharactersTypes {
  At = '@',
  Hash = '#',
  Asterisk = '*',
}

export interface CustomSuggestionDataItem {
  id: string | number;
  display?: string;
  [x: string]: string | number | undefined;
}

export type MentionDataTypes = {
  /** List of data to be used for mentioning */
  mentionData: SuggestionDataItem[];
  /** Trigger character for a specific sets of data */
  triggerCharacter: AvailableTriggerCharactersTypes;
};

export type BaseMentionInputProps<T> = {
  /** The field name associated with the DraftJS editor. */
  name: string;
  /** The label or placeholder for the DraftJS editor. */
  label?: string;
  /** The Formik `touched` object for tracking field interaction. */
  touched: FormikTouched<T>;
  /** The Formik `errors` object for tracking validation errors. */
  errors: FormikErrors<T>;
  /** Function to update the DraftJS editor value in Formik. */
  setFieldValue: (field: string, value: string) => void;
};

export type ExtendedMentionInputProps<T> = BaseMentionInputProps<T> & FieldProps;

export interface StringKeyedObject {
  [key: string]: string | Object | Array<Object>;
}
