export enum TaskTypeNameType {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  VOICE_CALL = 'VOICE_CALL',
  DEFAULT = '',
}

export type CrmTaskType = {
  id: number;
  taskTypeName: TaskTypeNameType;
  displayName: string;
  createdAt: string;
};
