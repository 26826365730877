import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BankingLoans } from '../custom/types/projectManagement/banks/goodLeap/goodLeapLoan';

interface ProposalLoansState {
  data: BankingLoans;
  isDataLoaded: boolean;
  totalProposalLoans: number;
}

const initialState: ProposalLoansState = {
  data: [],
  isDataLoaded: false,
  totalProposalLoans: 0,
};

export const proposalLoansSlice = createSlice({
  name: 'proposalLoans',
  initialState,
  reducers: {
    setProposalLoansData(state, action: PayloadAction<BankingLoans>) {
      state.data = action.payload;
    },
    setIsDataLoaded(state, action: PayloadAction<boolean>) {
      state.isDataLoaded = action.payload;
    },
    setTotalProposalLoans(state, action: PayloadAction<number>) {
      state.totalProposalLoans = action.payload;
    },
  },
});

export const { setProposalLoansData, setIsDataLoaded, setTotalProposalLoans } = proposalLoansSlice.actions;
