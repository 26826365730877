import { IdentityTwilioNumber } from '../../custom/types/identity/admin/twilio';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AvailableTwilioNumbersState {
  data: IdentityTwilioNumber[];
  isDataLoaded: boolean;
}

const initialState: AvailableTwilioNumbersState = {
  data: [],
  isDataLoaded: false,
};

export const availableTwilioNumbersSlice = createSlice({
  name: 'availableTwilioNumbers',
  initialState,
  reducers: {
    setAvailableTwilioNumbersData(state, action: PayloadAction<IdentityTwilioNumber[]>) {
      state.data = action.payload;
    },
    setIsDataLoaded(state, action: PayloadAction<boolean>) {
      state.isDataLoaded = action.payload;
    },
  },
});

export const { setAvailableTwilioNumbersData, setIsDataLoaded } = availableTwilioNumbersSlice.actions;
