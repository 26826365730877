/**
 * Formats a duration given in seconds into a human-readable string.
 *
 * @param {number | string} seconds - The duration in seconds. Can be a number or a string.
 * @param {boolean} [verbose=false] - If true, returns a verbose string (e.g., "1 hour 2 minutes 3 seconds").
 * @returns {string} - The formatted duration string.
 * @Output1
 * Verbose = false : 00:00:00
 * @Output2
 * Verbose = true : 1 hour 2 minutes 3 seconds
 */
export function formatDuration(seconds: number | string, verbose: boolean = false): string {
  // Convert seconds to a number if it is a string
  const totalSeconds = typeof seconds === 'string' ? parseInt(seconds, 10) : seconds;

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const secs = totalSeconds % 60;

  if (verbose) {
    return `${hours > 0 ? hours + (hours > 1 ? ' hours ' : ' hour ') : ''}${
      minutes > 0 ? minutes + (minutes > 1 ? ' minutes ' : ' minute ') : ''
    }${secs} ${secs === 1 ? 'second' : 'seconds'}`;
  } else {
    return `${hours > 0 ? `${hours}:` : ''}${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  }
}
