import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GridDisplayFinanceOffersRate } from '../custom/types/identity/admin/tenant/tenant';

interface FinanceOffersState {
  data: GridDisplayFinanceOffersRate[];
  isDataLoaded: boolean;
  totalFinanceOffers: number;
}

const initialState: FinanceOffersState = {
  data: [],
  isDataLoaded: false,
  totalFinanceOffers: 0,
};

export const financeOffersSlice = createSlice({
  name: 'financeOffers',
  initialState,
  reducers: {
    setFinanceOffersData(state, action: PayloadAction<GridDisplayFinanceOffersRate[]>) {
      state.data = action.payload;
    },
    setIsDataLoaded(state, action: PayloadAction<boolean>) {
      state.isDataLoaded = action.payload;
    },
    setTotalFinanceOffers(state, action: PayloadAction<number>) {
      state.totalFinanceOffers = action.payload;
    },
  },
});

export const { setFinanceOffersData, setIsDataLoaded, setTotalFinanceOffers } = financeOffersSlice.actions;
