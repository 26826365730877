import { ConversationTaskHistoryType } from '../../pages/task/taskTypes';
// import { CrmUserConversation } from '../conversation';
import { CrmAppointment } from './appointment';
import { CrmLead } from './lead';
import { IdentityNote } from '../identity/user/note';
import { CrmCallRecording } from './callRecording';
import { CrmUser } from './user';
import { ProjectType } from '../project';
import { CrmCallPayload } from './callPayload';

export type CrmNotifications = {
  crm: {
    userOptions: {
      notifications: CrmNotification[];
    };
  };
};

export enum CrmNotificationType {
  CRM_APPOINTMENT = 'CRM_APPOINTMENT',
  CRM_LEAD = 'CRM_LEAD',
  TWILIO_CONVERSATION = 'TWILIO_CONVERSATION',
  IDENTITY_NOTE = 'IDENTITY_NOTE',
  VOICEMAIL = 'VOICEMAIL',
  MISSED_CALL = 'MISSED_CALL',
}

export enum NotificationEntityContextType {
  USER = 'USER',
  LEAD = 'LEAD',
  PROJECT = 'PROJECT',
}

export type CrmNotification = {
  id: string;
  title: string;
  message: string;
  readAt: string | null;
  createdAt: string;
  remarks: string;
  data: CrmAppointment | CrmLead | ConversationTaskHistoryType | IdentityNote | CrmCallRecording | CrmCallPayload;
  type: CrmNotificationType;
  user: notificationEntity;
};

type notificationEntity = {
  entityContext: CrmUser | CrmLead | ProjectType;
  entityContextType: NotificationEntityContextType;
};

export type NotificationAppointment = {
  appointmentLocationId: number;
  appointmentRecipientId: number;
  appointmentTypeId: number;
  assignedToId: string;
  createdById: String;
  deletedAt: string;
  description: string;
  endTime: string;
  eventId: string;
  hasMeetingLink: boolean;
  id: number;
  leadId: number;
  startTime: string;
  title: string;
};

export type NotificationEntityContext = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  twilio: NotificationUserTwilio;
  tenantId: string;
  group: NotificationUserGroup;
};

export type NotificationUserTwilio = {
  projectAddress: string;
};

export type NotificationUserGroup = {
  id: string;
  name: string;
  firstName: string;
  createdAt: string;
  companyId: string;
};
