import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CrmCallRecording } from '../../custom/types/crm/callRecording';

interface VoicemailsState {
  data: CrmCallRecording[];
  isDataLoaded: boolean;
  totalVoicemails: number;
}

const initialState: VoicemailsState = {
  data: [],
  isDataLoaded: false,
  totalVoicemails: 0,
};

export const voicemailsSlice = createSlice({
  name: 'voicemails',
  initialState,
  reducers: {
    setVoicemailsData(state, action: PayloadAction<CrmCallRecording[]>) {
      state.data = action.payload;
    },
    setIsDataLoaded(state, action: PayloadAction<boolean>) {
      state.isDataLoaded = action.payload;
    },
    setTotalVoicemails(state, action: PayloadAction<number>) {
      state.totalVoicemails = action.payload;
    },
  },
});

export const { setVoicemailsData, setIsDataLoaded, setTotalVoicemails } = voicemailsSlice.actions;
