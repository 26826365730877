import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CrmTaskSchedule } from '../custom/types/crm/taskSchedule/taskSchedule';

interface TaskSchedulesState {
  data: CrmTaskSchedule[];
  isDataLoaded: boolean;
  totalTaskSchedules: number;
}

const initialState: TaskSchedulesState = {
  data: [],
  isDataLoaded: false,
  totalTaskSchedules: 0,
};

export const taskSchedulesSlice = createSlice({
  name: 'taskSchedules',
  initialState,
  reducers: {
    setTaskSchedulesData(state, action: PayloadAction<CrmTaskSchedule[]>) {
      state.data = action.payload;
    },
    setIsDataLoaded(state, action: PayloadAction<boolean>) {
      state.isDataLoaded = action.payload;
    },
    setTotalTaskSchedules(state, action: PayloadAction<number>) {
      state.totalTaskSchedules = action.payload;
    },
  },
});

export const { setTaskSchedulesData, setIsDataLoaded, setTotalTaskSchedules } = taskSchedulesSlice.actions;
