export const draftJSMentionStyle = `
.mentionSuggestions {
    background: white;
    border-radius: 2px;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    transform-origin: 50% 0%;
    transform: scaleY(0);
    margin-top: 2px;
    margin-left: 5px;
    overflow: auto;
    max-height: 260px;
    border: 1px solid #eee;
    z-index: 1;
  }

  .mentionSuggestionsEntryContainer {
    display: flex;
    align-items: center;
    padding: 2px;
  }

  .mentionSuggestionsEntryContainerLeft {
    margin-right: 0.313rem;
  }

  .mentionSuggestionsEntryContainerRight {
    display: flex;
    flex-direction: column;
  }

  .mentionSuggestionsEntry,
  .mentionSuggestionsEntryFocused {
      padding: 7px 10px 3px 10px;
      border: 1px solid transparent;
  }

  .mentionSuggestionsEntryFocused {
      background-color: #e6f3ff;
  }

  .mentionSuggestionsEntryText,
  .mentionSuggestionsEntryTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mentionSuggestionsEntryTitle {
    font-size: 80%;
    color: #a7a7a7;
  }

  .mentionSuggestionsEntryAvatar {
    display: block;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
  }
`;
