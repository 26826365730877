import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IdentityPurchasedPhoneNumber } from '../../../custom/types/identity/admin/twilio/phoneNumbers';

interface PurchasedNumbersState {
  data: IdentityPurchasedPhoneNumber[];
  isDataLoaded: boolean;
}

const initialState: PurchasedNumbersState = {
  data: [],
  isDataLoaded: false,
};

export const purchasedNumbersSlice = createSlice({
  name: 'purchasedNumbers',
  initialState,
  reducers: {
    setPurchasedNumbersData(state, action: PayloadAction<IdentityPurchasedPhoneNumber[]>) {
      state.data = action.payload;
    },
    setIsDataLoaded(state, action: PayloadAction<boolean>) {
      state.isDataLoaded = action.payload;
    },
  },
});

export const { setPurchasedNumbersData, setIsDataLoaded } = purchasedNumbersSlice.actions;
