import { CrmLead } from './lead';
import { CrmUser } from './user';

export type CrmCallPayloads = {
  crm: {
    userOptions: {
      callPayloads: CrmCallPayload[];
    };
  };
};

export type CrmCallPayloadPaged = {
  crm: {
    userOptions: {
      callPayloadsPaged: {
        totalCount: number;
        callPayloads: CrmCallPayload[];
      };
    };
  };
};

export type CrmCallPayloadReceiverType = CrmUser | CrmLead;
export type CrmCallPayloadCallerType = CrmUser | CrmLead;

export type CrmCallPayload = {
  id: number;
  sid: string;
  direction: string;
  caller: string;
  receiver: string;
  status: string;
  answeredBy: string;
  payload: string;
  duration: string;
  createdAt: string;
  receiverData: CrmCallPayloadReceiverType;
  callerData: CrmCallPayloadCallerType;
};

export enum CallStatusTypes {
  CONNECTING = 'Connecting',
  COMPLETED = 'Completed',
  BUSY = 'Busy',
  FAILED = 'Failed',
  NO_ANSWER = 'No-Answer',
  CANCELED = 'Canceled',
  INITIATED = 'Initiated',
}

export enum IncomingDialCallStatus {
  BUSY = 'busy',
  NO_ANSWER = 'no-answer',
  CANCELED = 'canceled',
  FAILED = 'failed',
  COMPLETED = 'completed',
}

export enum CallDirectionEnum {
  INBOUND = 'INBOUND',
  OUTBOUND_DIAL = 'OUTBOUND_DIAL',
}

export enum CallPayloadStatusEnum {
  INITIATED = 'INITIATED',
  RINGING = 'RINGING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  BUSY = 'BUSY',
  FAILED = 'FAILED',
  NO_ANSWER = 'NO_ANSWER',
  CANCELED = 'CANCELED',
}

export const mapCallPayloadCallerData = (callerData: CrmCallPayloadCallerType) => {
  if (callerData?.__typename === 'CrmUser') {
    const user = callerData as CrmUser;
    return {
      userId: user.id,
      userEmail: user.email,
      userFirstName: user.firstName,
      userLastName: user.lastName,
      userContactNo: user.twilio.projectedAddress,
    };
  }

  if (callerData?.__typename === 'CrmLead') {
    const lead = callerData as CrmLead;
    return {
      leadId: lead.id,
      leadName: lead.name,
      leadContactNo: lead.contactNo,
    };
  }

  return;
};
